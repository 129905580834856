const tacticBranding = {
  brandBlue: {
    50: "#F0F5FA",
    100: "#ecf2f8",
    200: "#dae6f1",
    300: "#b4cce4",
    400: "#8fb3d6",
    500: "#6999c9",
    600: "#366696",
    700: "#294d70",
    800: "#1b334b",
    900: "#111F2D",
  },
  brandGreen: {
    50: "#F2F7F6",
    100: "#F2F7F6",
    200: "#E5F0ED",
    300: "#D8E8E4",
    400: "#CCE1DC",
    500: "#B5D4CC",
    600: "#9fc6bc",
    700: "#7eb4a6",
    800: "#5ea190",
    900: "#396056",
  },
  brandYellow: {
    50: "#FDF7EC",
    100: "#FDF7EC",
    200: "#FDF7EC",
    300: "#FCEFD9",
    400: "#FBE7C6",
    500: "#f9ddae",
    600: "#F6CE88",
    700: "#f4c471",
    800: "#F0B042",
    900: "#EDA01D",
  },
  brandChestnut: {
    50: "#EFEAE7",
    100: "#DED4CE",
    200: "#CDBEB6",
    300: "#BDA99E",
    400: "#B59F92",
    500: "#A48A79",
    600: "#9C806D",
    700: "#927663",
    800: "#866C5B",
    900: "#796253",
  },
  brandCharcoal: {
    50: "#181818",
    100: "#181818",
    200: "#181818",
    300: "#181818",
    400: "#181818",
    500: "#181818",
    600: "#181818",
    700: "#181818",
    800: "#181818",
    900: "#181818",
  },
}

export { tacticBranding as colors }
