const buttonStyle = {
  baseStyle: {
    transition: "all 0.1s ease-out",
    letterSpacing: "wider",
    rounded: "full",
    _focus: {
      boxShadow: "none",
    },
  },

  variants: {
    green: props => ({
      bg: "transparent",
      color: "white",
      zIndex: 1,
      position: "relative",
      overflow: "hidden",
      border: "1px solid var(--tactic-colors-brandGreen-500)",
      transformOrigin: "center",
      _after: {
        content: "''",
        width: "100%",
        height: "100%",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        transition: "transform .15s cubic-bezier(.785,.135,.15,.86)",
        transformOrigin: "left center",
        bg: "brandGreen.500",
        position: "absolute",
        transform: "scale(1)",
        zIndex: "-1",
        rounded: "full",
      },
      _before: {
        content: "''",
        width: "100%",
        height: "100%",
        bg: "brandGreen.700",
        position: "absolute",
        zIndex: "-2",
      },
      _active: {
        transform: "scale(0.95)",
      },
      _hover: {
        borderColor: "brandGreen.700",
        transform: "scale(0.98)",
        _after: {
          transform: "scaleX(0)",
          transformOrigin: "right center",
        },
      },
    }),
    heroNav: props => ({
      bg: "transparent",
      color: "brandBlue.900",
      fontSize: ["12px", "12px", "14px"],
      zIndex: 1,
      position: "relative",
      overflow: "hidden",
      border: "1px solid white)",
      px: 4,
      py: 2,
      _after: {
        content: "''",
        width: "100%",
        height: "100%",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        transition: "transform .15s cubic-bezier(.785,.135,.15,.86)",
        transformOrigin: "left center",
        bg: "white",
        position: "absolute",
        transform: "scale(1)",
        zIndex: "-1",
        rounded: "full",
      },
      _before: {
        content: "''",
        width: "100%",
        height: "100%",
        bg: "brandGreen.500",
        position: "absolute",
        zIndex: "-2",
      },
      _active: {
        transform: "scale(0.95)",
      },
      _hover: {
        transform: "scale(0.98)",
        _after: {
          transform: "scaleX(0)",
          transformOrigin: "right center",
        },
      },
    }),
    dark: props => ({
      bg: "brandBlue.900",
      transition: "borderColor 0 ease-out",
      color: "white",
      zIndex: 1,
      position: "relative",
      overflow: "hidden",
      border: "1px solid var(--tactic-colors-brandBlue-900)",
      _after: {
        content: "''",
        width: "100%",
        height: "100%",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        transition: "transform .15s cubic-bezier(.785,.135,.15,.86)",
        transformOrigin: "left center",
        bg: "brandBlue.900",
        position: "absolute",
        transform: "scale(1)",
        zIndex: "-1",
        rounded: "full",
      },
      _before: {
        content: "''",
        width: "100%",
        height: "100%",
        bg: "brandBlue.900",
        position: "absolute",
        zIndex: "-2",
      },
      _active: {
        transform: "scale(0.95)",
      },
      _hover: {
        color: "brandBlue.900",
        bg: "transparent",
        _before: {
          bg: "white",
        },
        _after: {
          transform: "scaleX(0)",
          transformOrigin: "right center",
        },
        "svg, path": {
          color: "brandBlue.900",
        },
      },
    }),
    pill: props => ({
      bg: "transparent",
      fontSize: "sm",
      fontWeight: "medium",
      border: "1px solid #efefef",
      _hover: {
        bg: "gray.100",
      },
    }),
    pagination: props => ({
      width: ["3rem", "3rem", "3rem"],
      height: "3rem",
      lineHeight: "3rem",
      rounded: "xl",
      color: props.active ? "brandYellow.500" : "brandBlue.900",
      bg: props.active ? "brandBlue.900" : "gray.100",
      _hover: {
        bg: "gray.200",
      },
    }),
    circle: props => ({
      width: ["3.25rem", "3.25rem", "3.25rem"],
      height: "3.25rem",
      lineHeight: "3.25rem",
      borderRadius: "50%",
      border: "1px solid",
      borderColor: props.bgPrimary || "transparent",
      p: 0,
      bg: props.bgPrimary || "transparent",
      transition: "background .3s ease-out",
      overflow: "hidden",
      outline: "transparent",
      "&:before": {
        content: '""',
        borderRadius: "50%",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        bg: props.bgSecondary || "brandGreen.600",
        opacity: [1, 1, 0],
        zIndex: -2,
        transform: ["scale(1)", "scale(1)", "scale(0)"],
        transformOrigin: "50% 50%",
        transition: "all .3s ease",
        pointerEvents: "none",
      },
      "&:after": {
        content: '""',
        borderRadius: "50%",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        bg: props.bgActive || "brandCharcoal.500",
        opacity: 0,
        zIndex: -1,
        transform: "scale(0)",
        transformOrigin: "50% 50%",
        transition: "all .3s ease",
        pointerEvents: "none",
      },
      ":hover": {
        "&:before": {
          transform: "scale(1)",
          opacity: 1,
        },
        svg: {
          transform: "scaleX(0.8)",
        },
      },
      "&.active": {
        "&:after": {
          transform: "scale(1)",
          opacity: 1,
        },
      },
    }),
    menuToggle: {
      width: ["2.7rem", "2.7rem", "2.7rem", "3.25rem"],
      height: ["2.7rem", "2.7rem", "2.7rem", "3.25rem"],
      lineHeight: ["2.7rem", "2.7rem", "2.7rem", "3.25rem"],
      borderRadius: "50%",
      p: 0,
      border: 0,
      bg: ["brandGreen.600", "brandGreen.600", "brandGreen.600", "transparent"],
      transition: "background .3s ease-out",
      overflow: "hidden",
      outline: "white",
      svg: {
        zIndex: 10,
        transform: ["scale(0.8)", "scale(0.8)", "scale(0.8)", "scaleX(01.1)"],
        transition: "all .3s ease",
        pointerEvents: "none",
      },
      "&:before": {
        content: '""',
        borderRadius: "50%",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        bg: "brandGreen.500",
        opacity: [1, 1, 0],
        zIndex: -2,
        transform: ["scale(1)", "scale(1)", "scale(0)"],
        transformOrigin: "50% 50%",
        transition: "all .3s ease",
        pointerEvents: "none",
      },
      "&:after": {
        content: '""',
        borderRadius: "50%",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        bg: "brandBlue.900",
        opacity: 0,
        zIndex: -1,
        transform: "scale(0)",
        transformOrigin: "50% 50%",
        transition: "all .3s ease",
        pointerEvents: "none",
      },
      ":hover": {
        "&:before": {
          transform: "scale(1)",
          opacity: 1,
        },
        svg: {
          transform: "scaleX(0.8)",
        },
      },
      "&.active": {
        "&:after": {
          transform: "scale(1)",
          opacity: 1,
        },
        svg: {
          transform: "scale(1.2)",
          rect: {
            fill: "white",
          },
        },
      },
    },
  },

  sizes: {
    lg: {
      px: 12,
      py: 8,
      fontSize: "16",
    },
    sm: {
      px: 12,
      py: 6,
      fontSize: "14",
    },
    xs: {
      px: 6,
      py: 4,
      fontSize: "12",
    },
    xxs: {
      px: 2,
      py: 1,
      fontSize: "12",
    },
  },
}

export { buttonStyle as Button }
