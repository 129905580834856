import { createBreakpoints } from "@chakra-ui/theme-tools"

export const breakpoints = createBreakpoints({
  sm: "391px",
  md: "768px",
  lg: "1080px",
  xl: "1366px",
  "2xl": "1660px",
  "3xl": "1800px",
})
