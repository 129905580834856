import React from "react"
import { useFlexSearch } from "react-use-flexsearch"

function PropertySearchResults({
  query,
  loadedPropertyIndexStore,
  setMatchedPropertiesGlobalState,
}) {
  const matches = useFlexSearch(
    query.query,
    JSON.stringify(loadedPropertyIndexStore.index),
    loadedPropertyIndexStore.store
  )

  React.useEffect(() => {
    setMatchedPropertiesGlobalState(matches)
  }, [matches, setMatchedPropertiesGlobalState])

  return <></>
}

export default PropertySearchResults
