import * as React from "react"
import Wrapper from "./src/components/search/SearchModal/context"
import PropertySearchContext from "./src/components/search/PropertySearch/context"
import ShortlistContext from "./src/components/page-specific/shortlist/ShortlistContext"
import { Script } from "gatsby"

export const wrapRootElement = ({ element }, pluginOptions) => (
  <PropertySearchContext>
    <ShortlistContext>
      <Wrapper element={element} {...pluginOptions} />
    </ShortlistContext>
  </PropertySearchContext>
)

export const wrapPageElement = ({ element }) => {
  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtm.js?id=GTM-W6LCTKZ`}
        forward={[`dataLayer.push`]}
      />
      <Script id="gtm-init">
        {`
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({ 'gtm.start': new Date().getTime(), 'event': 'gtm.js' })
        `}
      </Script>
      {element}
    </>
  )
}

export function onRouteUpdate() {
  if (process.env.NODE_ENV === `production`) {
    setTimeout(() => {
      const data = window.dataLayer
      const eventName = `gatsby-route-change`
      data.push({
        event: eventName,
      })
    }, 200)
  }
}

function wrapContent() {
  if (typeof document !== "undefined") {
    const content = document.querySelector("#___gatsby")
    const smoothContent = document.createElement("div")
    const smoothWrapper = document.createElement("div")
    smoothContent.setAttribute("id", "smooth-content")
    smoothWrapper.setAttribute("id", "smooth-wrapper")
    smoothContent.appendChild(content)
    smoothWrapper.appendChild(smoothContent)
    const navContainer = document.createElement("div")
    navContainer.setAttribute("id", "navContainer")
    const listingModalContainer = document.createElement("div")
    listingModalContainer.setAttribute("id", "listingModalContainer")
    const body = document.querySelector("body")
    body.appendChild(navContainer)
    body.appendChild(smoothWrapper)
    body.appendChild(listingModalContainer)
  }
}

wrapContent()
