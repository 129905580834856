export const Center = {
  // Styles for the base style
  baseStyle: {},
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  variants: {
    green: props => ({
      bg: props.bg || "brandGreen.600",
    }),
  },
  // The default `size` or `variant` values
  defaultProps: {},
}
