import React from "react"
import Cookies from "js-cookie"

export const ShortlistContext = React.createContext()

function ShortlistContextWrapper({ children }) {
  const [shortlistedPropertyIds, setShortlistedPropertyIds] = React.useState([])

  React.useEffect(() => {
    const cookie = Cookies.get("shortlist")
    if (!cookie) {
      Cookies.set("shortlist", JSON.stringify([]), { expires: 7 })
    } else {
      const shortlist = JSON.parse(cookie)
      setShortlistedPropertyIds(shortlist)
    }
  }, [])

  React.useEffect(() => {
    Cookies.remove("shortlist")
    Cookies.set("shortlist", JSON.stringify(shortlistedPropertyIds), {
      expires: 7,
    })
  }, [shortlistedPropertyIds])

  return (
    <ShortlistContext.Provider
      value={{ shortlistedPropertyIds, setShortlistedPropertyIds }}
    >
      {children}
    </ShortlistContext.Provider>
  )
}

export default ShortlistContextWrapper
