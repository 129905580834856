//-----------------------------------------------//
// Place all global CSS for every page here //
//-----------------------------------------------//

const globalCSS = {
  body: {
    color: "brandCharcoal.500",
    overflowX: "hidden",
  },
  h1: {
    fontSize: ["3xl", "3xl", "4xl", "4xl", "6xl"],
    fontWeight: "bold",
    lineHeight: "110%",
    letterSpacing: "-1%",
    color: "brandBlue.900",
  },
  h2: {
    fontSize: ["xl", "3xl", "3xl", "3xl", "4xl"],
    fontWeight: "bold",
    lineHeight: "shorter",
    letterSpacing: "normal",
    color: "brandBlue.900",
    letterSpacing: "-1%",
  },
  h3: {
    fontSize: ["xl", "1xl", "2xl", "2xl", "2xl"],
    fontWeight: "bold",
    lineHeight: "shorter",
    letterSpacing: "-2%",
    color: "brandBlue.900",
    letterSpacing: "-1%",
  },
  h4: {
    fontSize: ["md", "lg", "lg"],
    fontWeight: "semibold",
    lineHeight: "shorter",
    letterSpacing: "wide",
    color: "brandBlue.900",
    letterSpacing: "-1%",
  },
  h5: {
    fontSize: ["sm", "sm", "md"],
    fontWeight: "semibold",
    lineHeight: "shorter",
    letterSpacing: "wide",
    color: "brandBlue.900",
    letterSpacing: "-1%",
  },
  p: {
    letterSpacing: "normal",
    fontWeight: "normal",
    color: "gray.600",
    fontSize: ["14px", "14px", "15px", "17px", "18px"],
    lineHeight: "short",
  },
  span: { color: "gray.600" },
  blockquote: {
    p: 6,
    rounded: "20px",
    bg: "brandGreen.500",
  },
  a: {
    color: "brandGreen.700",
    fontWeight: "medium",
    position: "relative",
  },
  li: {
    listStyle: "none",
  },
  "::selection": {
    backgroundColor: "brandGreen.500",
    color: "brandBlue.900",
  },
  "#navPopup.hidden": {
    display: "none",
  },
  ".stop-scroll": {
    overflow: "hidden",
  },
  ".hero-half-slider": {
    height: "100%",
  },

  ".hero-slider": {
    h: "100%",
    w: "100%",
    position: "relative",
    ".swiper-slide": {
      w: "100%",
    },
  },

  ".hero-half-slider .swiper-slide": {
    maxW: "100vw",
  },
  ".swiper-button-prev": {
    svg: {
      pointerEvents: "none",
    },
  },
  ".swiper-button-next": {
    svg: {
      pointerEvents: "none",
    },
  },
  ".white-svg": {
    path: {
      fill: "white",
    },
  },
  ".wsywyg": {
    ".gatsby-image-wrapper": {
      borderRadius: "0.75rem",
      maxH: "550px !important",
    },

    img: {
      borderRadius: "0.75rem",
      maxH: "550px !important",
    },

    blockQuote: {
      p: {
        marginBottom: "unset",
      },
    },

    li: {
      listStyle: "unset",
      listStylePosition: "inside",
      color: "gray.600",
      lineHeight: "tall",
    },

    h1: {
      marginBottom: ["1.2rem", "1.2rem", "2rem"],
      color: "brandBlue.900",
      fontWeight: "700!important",
    },
    ul: {
      marginBottom: ["1.2rem", "1.2rem", "2rem"],
    },
    h2: {
      fontSize: ["xl", "2xl", "3xl", "3xl", "3xl"],
      lineHeight: "shorter",
      letterSpacing: "normal",
      marginBottom: ["1.2rem", "1.2rem", "2rem"],
      color: "brandBlue.900",
      fontWeight: "700!important",
    },
    p: {
      marginBottom: ["1.2rem", "1.2rem", "2rem"],
    },
    h3: {
      marginBottom: ["1.2rem", "1.2rem", "2rem"],
      color: "brandBlue.900",
      fontWeight: "700!important",
    },
    h4: {
      marginBottom: ["1.2rem", "1.2rem", "2rem"],
      color: "brandBlue.900",
      fontWeight: "700!important",
    },
    blockquote: {
      marginBottom: ["1.2rem", "1.2rem", "2rem"],
    },
  },

  "#fp-nav.fp-right ul li": {
    marginTop: ["0.5rem", "0.5rem", "1.3rem"],
  },

  "#fp-nav.fp-right ul li a.active span": {
    background: "var(--tactic-colors-brandYellow-500)",
  },

  "#fp-nav.fp-right ul li a:not(.active) span": {
    backgroundColor: "white",
    transform: "scale(1.5)",
    transformOrigin: "50% 50%",
  },

  ".dark #fp-nav.fp-right ul li a:not(.active) span": {
    backgroundColor: "brandCharcoal.500",
  },
  "#fp-nav": {
    display: ["none", "none", "block"],
  },
  "#footerWrap .fp-tableCell": {
    verticalAlign: "bottom",
  },
  ".titleReveal": {
    overflow: "hidden",
  },
  ".word-wrap": {
    pb: "4px",
  },
  ".primaryMenuLink": {
    transition: "all 0.1s ease-in-out",
    overflowY: "hidden",
    _hover: {
      color: "white",
      transform: "skewX(4deg) translateY(3px)",
    },
  },
  ".subMenuLink": {
    transition: "all 0.1s ease-in-out",
    _hover: {
      color: "white",
      transform: "skewX(4deg) translateY(1.5px)",
    },
  },
  ".pulse.active": {
    transform: "scale(1.5)",
  },
  ".swiper-slide.card": {
    boxShadow:
      "rgba(0, 0, 0, 0.03) 0px 20px 25px -5px, rgba(0, 0, 0, 0.02) 0px 10px 10px -5px",
    borderRadius: "var(--tactic-radii-xl)",
  },
  ".infographic-nav": {
    cursor: "pointer",
  },
  ".hidden": { visibility: "hidden" },
  footer: {
    a: {
      p: {
        transition: "all 0.05s ease",
        _hover: {
          color: "white",
        },
      },
      li: {
        transition: "all 0.05s ease",
        _hover: {
          color: "white",
        },
      },
    },
  },
  ".author-image": {
    borderRadius: "50%",
    overflow: "hidden",
    width: ["200px", "200px", "100px"],
    height: ["200px", "200px", "100px"],
    backfaceVisibility: "hidden",
    transform: "translate3d(0, 0, 0)",
  },
  ".author-image.small": {
    borderRadius: "50%",
    overflow: "hidden",
    width: ["60px", "60px", "100px"],
    minWidth: ["60px", "60px", "100px"],
    height: ["60px", "60px", "100px"],
  },
  ".author-image.xs": {
    borderRadius: "50%",
    overflow: "hidden",
    width: ["60px", "60px"],
    height: ["60px", "60px"],
  },
  ".author-image.lg": {
    width: ["80px", "80px", "80px", "210px", "210px", "210px"],
    minWidth: ["80px", "80px", "80px", "210px", "210px", "210px"],
    height: ["80px", "80px", "80px", "210px", "210px", "210px"],
  },
  "#description h1,#description h2,#description h3,#description h4,strong ": {
    color: "brandBlue.900",
  },
  "#description ul li": {
    listStyle: "disc",
  },
  "#description ul": {
    listStylePosition: "inside",
    pl: "1rem",
  },
  "#tacticSpaces .overflow-hidden": {
    backfaceVisibility: "hidden",
    transform: "translate3d(0, 0, 0)",
  },

  ".chakra-ui-light .chakra-portal .chakra-portal-zIndex": {
    zIndex: "unset !important",
  },
}

const globalStyle = {
  global: {
    ...globalCSS,
  },
}

export { globalStyle as styles }
