import React from "react"
import SetPropertySearchResults from "./SetPropertySearchResults"

export const SearchContext = React.createContext({})

function PropertySearchContext({ children }) {
  const initialSearchState = {
    query: "",
    minValue: 0,
    maxValue: 5000,
  }
  const [queryGlobalState, setQueryGlobalState] =
    React.useState(initialSearchState)
  const [matchedPropertiesGlobalState, setMatchedPropertiesGlobalState] =
    React.useState([])
  const [loadedPropertyIndexStore, setLoadedPropertyIndexStore] =
    React.useState(null)

  return (
    <SearchContext.Provider
      value={{
        queryGlobalState,
        setQueryGlobalState,
        matchedPropertiesGlobalState,
        setMatchedPropertiesGlobalState,
        loadedPropertyIndexStore,
        setLoadedPropertyIndexStore,
        initialSearchState,
      }}
    >
      {loadedPropertyIndexStore && (
        <SetPropertySearchResults
          query={queryGlobalState}
          loadedPropertyIndexStore={loadedPropertyIndexStore}
          setMatchedPropertiesGlobalState={setMatchedPropertiesGlobalState}
        />
      )}
      {children}
    </SearchContext.Provider>
  )
}

export default PropertySearchContext
